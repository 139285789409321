let Inspection = function(tokenClient, serviceAddr) {
	let TokenClient = tokenClient
	let ServiceBaseAddress = serviceAddr

	/**
	 * 获取检查检验字典首页
	 * @param {any} success
	 * @param {any} error
	 */
	this.Inspections = function(success, error) {
		var url = ServiceBaseAddress + '/api/Inspection'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 获取检查检验字典详情
	 * @param {any} success
	 * @param {any} error
	 */
	this.Detail = function(params, success, error) {
		var url = ServiceBaseAddress + '/api/Inspection/' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
	/**
	 * 获取化验单列表
	 * @param {any} success
	 * @param {any} error
	 */
	this.ReportAll = function(params, success, error) {
		var url =
			ServiceBaseAddress + '/api/LaboratoryTestReport/Reports' + params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	/**
	 * 获取化验单详情
	 * @param {any} success
	 * @param {any} error
	 */
	this.ReportDetail = function(params, success, error) {
		var url =
			ServiceBaseAddress +
			'/api/LaboratoryTestReport/Reports/Detail/' +
			params
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}

	/**
	 * 获取常见化验单列表
	 * @param {any} success
	 * @param {any} error
	 */
	this.OftenReport = function(success, error) {
		var url = ServiceBaseAddress + '/api/LaboratoryTestReport/Reports/OftenReport'
		TokenClient.Get(
			url,
			true,
			null,
			null,
			function(data) {
				if (success) {
					var jsonObj = JSON.parse(data)
					success(jsonObj)
				}
			},
			error
		)
	}
}

export { Inspection }
